import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {connect} from 'react-redux';
import {showNotification, refreshView} from 'react-admin';
import {compose} from 'recompose';
import {withDataProvider} from 'ra-core';

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center'
  }
};

const PinnedToggleButton = ({
  record,
  dataProvider,
  showNotification,
  refreshView,
  classes
}) => {
  const [loading, setLoading] = useState(false)

  const handleToggle = async (event) => {
    event.stopPropagation()

    if (loading) return
    setLoading(true)

    try {
      const updateRecord = {...record}
      updateRecord.isPinned = !record.isPinned
      await dataProvider('PINNED', 'community', {
        id: record.id,
        data: updateRecord,
      })

      showNotification(
        `게시글이 ${updateRecord.isPinned ? '상단에 고정' : '고정 해제'} 되었습니다`,
        'success'
      )
      refreshView()
    } catch (error) {

      switch (error.message) {
        case 'max_pinned_posts_exceeded':
          showNotification(
            `상단 고정 최대 개수를 초과했습니다.`,
            'warning'
          )
          break

        default:
          showNotification(
            `상단 고정 변경 실패 - ${error.message || '알 수 없는 오류'}`,
            'warning'
          )
          break
      }
    } finally {
      setLoading(false)
    }
  }

  if (!record) return null

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classes.container}
    >
      <Switch
        checked={record.isPinned || false}
        onChange={handleToggle}
        color="primary"
        disabled={loading}
      />
    </div>
  );
};

export default compose(
  connect(null, {
    showNotification,
    refreshView,
  }),
  withStyles(styles),
  withDataProvider
)(PinnedToggleButton)