import englishMessages from 'ra-language-english'
import treeEnglishMessages from 'ra-tree-language-english'
import {mergeTranslations} from 'react-admin'

export const messages = {
  ...mergeTranslations(englishMessages, treeEnglishMessages),
  ra: {
    action: {
      add_filter: '필터 추가',
      add: '추가',
      back: '뒤로',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: '취소',
      close: '닫기',
      confirm: '확인',
      clear_input_value: 'Clear value',
      clone: '복사',
      create: '추가',
      delete: '삭제',
      edit: '수정',
      export: 'Export',
      list: '목록',
      ok: '확인',
      refresh: '새로고침',
      remove_filter: 'Remove this filter',
      remove: '삭제',
      save: '저장',
      search: '검색',
      show: '조회',
      sort: 'Sort',
      undo: 'Undo'
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      cancel_content: '정말로 %{name} #%{id}을 취소하시겠습니까?',
      cancel_title: '%{name} #%{id} 취소',
      delete_content: '정말로 %{name} #%{id}을 삭제하시겠습니까?',
      delete_title: '%{name} #%{id} 삭제',
      details: 'Details',
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: '입력이 유효하지 않습니다. 에러를 확인해 주세요.',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes'
    },
    navigation: {
      no_results: '항목이 없습니다.',
      no_more_results: 'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd}, %{total}',
      page_rows_per_page: '페이지 행:',
      next: '다음',
      prev: '이전'
    },
    notification: {
      updated: '업데이트 되었습니다. |||| %{smart_count} elements updated',
      created: '생성되었습니다.',
      deleted: '삭제 되었습니다. |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: '해당 내용이 없습니다.',
      http_error: '해당 값이 없거나 잘못 요청되었으니, 요청 조건을 확인하시거나 화면을 갱신하십시오',
      data_provider_error: 'dataProvider error. Check the console for details.',
      cancelled: 'Action cancelled'
    },
    page: {
      create: '%{name} 생성',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not Found',
      show: '%{name} #%{id}'
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.'
      },
      image: {
        upload_several: '이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.',
        upload_single: '이미지를 끌어다 넣거나, 클릭해서 선택하십시오.'
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing: 'At least one of the associated references no longer appears to be available.',
        single_missing: 'Associated reference no longer appears to be available.'
      }
    }
  },
  common: {
    name: '이름',
    value: '정보'
  },
  simple: {
    action: {
      close: 'Close',
      save: 'Save'
    }
  },
  auth: {
    userName: '사용자 이름',
    password: '패스워드',
    signIn: '로그인'
  },
  resources: {
    admins: {
      name: '관리자 설정',
      fields: {
        id: 'ID',
        name: '아이디',
        adminName: '이름',
        affiliation: '소속',
        phone: '전화번호',
        email: '이메일',
        auth: '권한',
        affiliationId: '소속',
        emailNoti: '이메일 노티',
        password: '비밀번호',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    home: {
      name: '홈 화면 설정',
      fields: {
        id: '순서',
        name: '영역 이름',
        display: '표출 여부',
        updatedAt: '수정일'
      }
    },
    homePointCharging: {
      name: '포인트 충전소 설정',
      fields: {
        id: '순서',
        name: '영역 이름',
        display: '표출 여부',
        updatedAt: '수정일'
      }
    },
    designSource: {
      name: '디자인 소스 설정',
      fields: {
        id: 'ID',
        name: '이름',
        titleImage: '이미지',
        category: '대분류',
        detailCategory: '중분류',
        createdAt: '생성일'
      }
    },
    gaReports: {
      name: 'GA 리포트'
    },
    // -------- 사용자 관리
    users: {
      name: '사용자 관리',
      summary: '기본 정보',
      orderHistory: '구매 내역',
      questionHistory: '문의 내역',
      sellHistory: '판매 내역',
      devices: '보유 기기',
      fields: {
        expirePoint: '소멸예정 포인트',
        referrerId: '추천인',
        agreeMarketing: '마케팅 동의 여부',
        search: '닉네임, Email, 전화번호',
        id: 'ID',
        walkCount: '산책 수',
        postCount: '포스팅 수',
        commentCount: '댓글 수',
        likeCount: '좋아요 수',
        friendsCount: '펫친 수',
        purchaseCount: '구매 횟수',
        purchasePrice: '구매 가격',
        name: '닉네임',
        phone: '전화번호',
        email: '이메일',
        accounts: '가입방식',
        orderCount: '구매 건수',
        orderPrice: '구매 금액',
        point: '포인트',
        createdAt: '가입일'
      }
    },
    deletedUsers: {
      name: '삭제된 유저 관리',
      fields: {
        id: 'ID',
        phone: '전화번호',
        name: '닉네임',
        accounts: '가입방식',
        point: '포인트',
        expirePoint: '소멸예정 포인트',
        'accounts.email': '이메일',
        agreeMarketing: '마케팅 동의 여부',
        referrerId: '추천인',
        redeemCodeId: '추천인 코드 제목',
        walkCount: '산책수',
        postCount: '포스팅',
        purchaseCount: '구매 횟수',
        purchasePrice: '구매 가격',
        createdAt: '탈퇴일',
        registeredAt: '가입일'
      }
    },
    messages: {
      name: '쪽지 관리',
      fields: {
        id: 'ID',
        userId: '수신자 ID',
        senderId: '발신자 ID',
        text: '메세지 내용',
        read: '읽음',
        createdAt: '생성일',
        userName: '수신자 이름',
        senderName: '발신자 이름'
      }
    },
    points: {
      name: '포인트 관리',
      fields: {
        remainPoint: '남은 포인트',
        description: '내용',
        expireAt: '소멸 날짜',
        usedCount: '사용한 횟수',
        redeemId: '쿠폰 ID',
        code: '코드',
        userId: 'User ID',
        userAt: '사용한 시간',
        count: '쿠폰 갯수',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        id: 'ID',
        etc: '기타',
        point: '포인트',
        name: '이름',
        sellerId: 'Seller ID',
        title: '제목',
        subTitle: '부제목',
        content: '내용',
        createdAt: '생성일',
        type: '종류',
        imagePath: '이미지'
      }
    },
    breeds: {
      name: '견종 관리',
      fields: {
        id: 'ID',
        name: '이름',
        adminName: '이름',
        order: '우선 순위',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    // -------- 추천인 코드 관리
    redeems: {
      name: '추천인 코드 관리',
      fields: {
        usedCount: '사용한 횟수',
        redeemId: '쿠폰 ID',
        code: '코드',
        userId: 'User ID',
        userAt: '사용한 시간',
        count: '쿠폰 갯수',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        id: 'ID',
        point: '포인트',
        name: '이름',
        sellerId: 'Seller ID',
        title: '제목',
        subTitle: '부제목',
        content: '내용',
        createdAt: '생성일',
        type: '종류',
        imagePath: '이미지'
      }
    },
    // -------- 커뮤니티 관리
    notices: {
      name: '공지사항 관리',
      fields: {
        id: 'ID',
        type: '타입',
        form: '이벤트 형태',
        content: '부제목',
        images: '이미지',
        titleImagePath: '대표 이미지',
        title: '제목',
        pushTitle: '푸시 제목(수정할 것)',
        push: '푸쉬 알림',
        totalReadCount: '조회 수',
        readCount: '조회 수',
        totalLikeCount: '좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '댓글 수',
        commentCount: '댓글 수',
        rolling_order: '롤링 순서',
        createdAt: '생성일',
        updatedAt: '수정일',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        periodStart: '오픈 날짜',
        periodEnd: '마감 날짜'
      }
    },
    noticeComments: {
      name: '공지사항 댓글 관리',
      fields: {
        id: '댓글 ID',
        userId: '작성자',
        noticeId: '공지사항 ID',
        title: '공지사항 제목',
        content: '댓글 내용',
        createdAt: '생성일'
      }
    },
    communityPopular: {
      name: '인기글 관리',
      fields: {
        thumbnailImage: '썸네일 이미지',
        id: 'ID',
        userId: '아이디',
        adminName: '이름',
        email: '이메일',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalLikeCount: '누적 좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '누적 댓글 수',
        commentCount: '댓글 수',
        createdAt: '생성일',
        updatedAt: '수정일',
        category: '게시글 유형',
        title: '제목',
        description: '내용'
      }
    },
    talks: {
      name: '게시글 관리',
      fields: {
        thumbnailImage: '썸네일 이미지',
        id: 'ID',
        userId: '아이디',
        adminName: '이름',
        email: '이메일',
        pinned: '상단 고정',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalLikeCount: '누적 좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '누적 댓글 수',
        commentCount: '댓글 수',
        createdAt: '생성일',
        updatedAt: '수정일',
        category: '게시글 유형',
        title: '제목',
        description: '내용'
      }
    },
    comments: {
      name: '게시글 댓글 관리',
      fields: {
        id: 'ID',
        userId: '작성자',
        talkId: '게시글',
        content: '댓글 내용',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    categoryNotice: {
      name: '카테고리 관리',
      fields: {
        id: 'ID',
        name: '이름',
        isActive: '활성화 여부',
        order: '우선 순위',
        createdAt: '생성일'
      }
    },
    communityCategory: {
      name: '카테고리 관리',
      fields: {
        id: 'ID',
        name: '이름',
        is_active: '활성화 여부',
        display_order: '우선 순위',
        createdAt: '생성일'
      }
    },
    // -------- 체험단 관리
    seedCampaign: {
      name: '체험단 관리',
      fields: {
        id: 'ID',
        title: '체험단 명',
        description: '체험단 부가 설명',
        titleImage: '대표 이미지',
        detailImages: '상세 이미지',
        applyCount: '신청 인원',
        recruitmentCount: '모집 인원',
        channel: '채널',
        status: 'zz',
        announcement: '채널 공지',
        provision: '제공 내역',
        keyword: '검색 키워드',
        mission: '체험단 미션',
        additionalGuide: '추가 안내 사항',
        pick: '주목할 체험단 표출여부',
        openPlanned: '오픈 예정 여부',
        attentionPoint: '유의 사항 정보',
        readCount: '조회 수',
        totalReadCount: '조회 수',
        recruitmentStartAt: '체험단 모집 시작일',
        recruitmentEndAt: '체험단 모집 마감일 ',
        registrationEndAt: '체험단 업로드 마감일',
        announcedAt: '결과 발표 일',
        createdAt: '체험단 포스팅 생성일'
      }
    },
    seedCampaignInquiry: {
      name: '문의 관리',
      fields: {
        id: 'ID',
        userId: '유저 ID',
        inquiryImages: '문의 사진',
        description: '문의 내용',
        createdAt: '생성일',
        seedCampaignId: '체험단 ID',
        seedCampaignInquiryCategoryId: '체험단 문의 카테고리 ID',
        isAnswered: '답변 유무',
        answer: '답변 내용',
        answeredAt: '답변 날짜'
      }
    },
    seedCampaignInquiryCategory: {
      name: '체험단 문의 카테고리',
      fields: {
        id: 'ID',
        name: '이름',
        isActive: '활성화 여부',
        displayOrder: '우선 순위',
        createdAt: '생성일'
      }
    },
    seedCampaignBestReview: {
      name: '베스트 리뷰 관리',
      fields: {
        id: 'ID',
        seedCampaignId: '체험단',
        userId: '유저',
        connectedUrl: '리뷰가 존재하는 URL',
        titleImage: '대표 이미지',
        totalReadCount: '총 조회수',
        displayOrder: '노출 순서',
        isActive: '표출 여부',
        createdAt: '생성일'
      }
    },
    seedCampaignApply: {
      name: '참여 인원 관리',
      fields: {
        id: 'ID'
      }
    },
    seedCampaignAdvertiser: {
      name: '광고주 관리',
      fields: {
        id: 'ID',
        name: '이름',
        createdAt: '생성일'
      }
    },
    // -------- 이벤트 관리
    events: {
      name: '이벤트 관리',
      fields: {
        id: 'ID',
        type: '타입',
        form: '이벤트 형태',
        content: '부제목',
        images: '이미지',
        titleImagePath: '대표 이미지',
        bottomImages: '하단 이미지',
        title: '제목',
        pushTitle: '푸시 제목(수정할 것)',
        push: '푸쉬 알림',
        totalReadCount: '조회 수',
        readCount: '조회 수',
        totalLikeCount: '좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '댓글 수',
        commentCount: '댓글 수',
        rolling_order: '노출 순서',
        createdAt: '생성일',
        updatedAt: '수정일',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        periodStart: '오픈 날짜',
        periodEnd: '마감 날짜'
      }
    },
    eventsComments: {
      name: '이벤트 댓글 관리',
      fields: {
        id: '댓글 ID',
        userId: '작성자',
        noticeId: '이벤트 ID',
        title: '이벤트 제목',
        content: '댓글 내용',
        createdAt: '생성일'
      }
    },
    // -------- 신고 관리
    ugcReports: {
      name: '전체 신고 관리',
      fields: {
        id: 'ID',
        ugcUserId: '신고 대상 유저 ID',
        ugcId: '신고글 ID',
        ugcType: '신고 대상 분류',
        userId: '신고자 ID',
        title: '제목',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        description: '내용',
        category: '신고 타입',
        isDelete: '삭제 여부',
        targetName: '대상 종류',
        targetId: '원문 ID (삭제된 원문은 X 표기가 됩니다)',
        targetContent: '신고 내용 (삭제된 원문은 X 표기가 됩니다)'
      }
    },
    // -------- 광고 관리
    advertising: {
      name: '광고 관리',
      fields: {
        id: 'ID',
        type: '종류',
        title: '제목',
        content: '내용',
        adUrl: '광고 파라미터',
        order: '우선 순위',
        onOff: '표출 여부',
        images: '이미지',
        thumbnailImage: '썸네일',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalCommentCount: '누적 댓글 수',
        commentCount: '댓글 수',
        createdAt: '생성일',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        exhibitionId: '기획전 종류',
        character: '캐릭터 선택'
      }
    },
    popups: {
      name: '팝업 관리',
      summary: '앱에 표출할 팝업을 관리',
      fields: {
        title: '제목',
        landing: '팝업 위치',
        type: '랜딩 페이지',
        urlTarget: 'URL / 게시글ID',
        content: '내용(250자 이내)',
        image: '이미지',
        userClick: '누적 클릭수',
        isAd: '광고 표기 여부',
        isOfferwall: '오퍼월 적용 여부',
        rewardPoint: '지급 포인트',
        startDate: '시작일',
        endDate: '종료일'
      }
    },
    // -------- 포인트 교환소 관리
    pointExchange: {
      name: '포인트 교환소 관리',
      summary: '포인트 교환소 운영을 위함',
      fields: {
        usePoint: '포인트 적용율',
        category: '카테고리 이름',
        id: 'ID',
        name: '이름',
        isTodayFee: '당일 배송비 적용',
        todayFeeImage: '당일 배송 가이드 이미지',
        deliveryFee: '상품 기본 배송비',
        extraFee: '도서 산간 추가 배송비',
        categoryId: '카테고리 번호',
        discountRate: '할인율',
        originalPrice: '상품 가격',
        onSale: '판매 여부',
        titleImagePath: '대표 이미지',
        images: '이미지',
        detailImages: '상세 이미지',
        price: '판매 가격',
        readCount: '조회수',
        createdAt: '생성일',
        updatedAt: '수정일',
        options: '옵션',
        exhibitionIds: '기획전',
        packing: '합포 가능 여부',
        startDate: '시작 일자',
        endDate: '종료 일자',
        hotDealPrice: '핫딜가(1 이상시 핫딜 적용)'
      }
    },
    // -------- 쇼핑 관리
    products: {
      name: '상품 관리',
      fields: {
        usePoint: '포인트 적용율',
        category: '카테고리 이름',
        id: 'ID',
        name: '이름',
        isTodayFee: '당일 배송비 적용',
        todayFeeImage: '당일 배송 가이드 이미지',
        deliveryFee: '상품 기본 배송비',
        extraFee: '도서 산간 추가 배송비',
        categoryId: '카테고리 번호',
        discountRate: '할인율',
        originalPrice: '상품 가격',
        onSale: '판매 여부',
        titleImagePath: '대표 이미지',
        images: '이미지',
        detailImages: '상세 이미지',
        price: '판매 가격',
        readCount: '조회수',
        createdAt: '생성일',
        updatedAt: '수정일',
        options: '옵션',
        exhibitionIds: '기획전',
        packing: '합포 가능 여부',
        startDate: '시작 일자',
        endDate: '종료 일자',
        hotDealPrice: '핫딜가(1 이상시 핫딜 적용)'
      }
    },
    categories: {
      name: '카테고리 관리',
      fields: {
        order: '우선 순위',
        imagePath: '이미지',
        id: 'ID',
        name: '이름',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    productQAs: {
      name: '상품 문의 관리',
      summary: '사용자의 문의 관리 메뉴',
      fields: {
        name: '상품명',
        question: '문의 내용',
        answer: '답변 내용',
        questionerId: '문의자',
        answererId: '답변자',
        questionDate: '문의일',
        answerDate: '답변일',
        answerer: '답변자'
      }
    },
    productReviews: {
      name: '상품 리뷰 관리',
      summary: '사용자의 제품 리뷰 관리',
      fields: {
        period: '특정기간 선택',
        title: '제목',
        orderId: '주문 번호',
        productId: '상품 번호',
        name: '상품명',
        rating: '평점',
        thumbnailPath: '대표 이미지',
        description: '리뷰 내용',
        userId: '등록자',
        createdAt: '리뷰 등록일',
        onSale: '전시 상태',
        reply: '답글 여부',
        replyDate: '답글일',
        answered: '답글일'
      }
    },
    productReviewReports: {
      name: '리뷰 신고 관리',
      fields: {
        reviewId: '리뷰 번호',
        userId: '사용자 ID',
        isReviewDelete: '리뷰 삭제',
        title: '제목',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        description: '내용',
        category: '신고 타입'
      }
    },
    exhibition: {
      name: '기획전 관리',
      fields: {
        id: 'ID',
        name: '이름',
        title: '제목',
        subTitle: '부제목',
        content: '내용',
        createdAt: '생성일',
        bannerPath: '배너 이미지',
        showType: '이미지 타입',
        type: '포인트 종류',
        order: '순위'
      }
    },
    banners: {
      name: '배너 관리',
      fields: {
        userClick: '누적 클릭수',
        onOff: '표출 여부',
        isAd: '광고 표기 여부',
        order: '우선 순위',
        position: '리스트 내 배너 위치',
        isOfferwall: '오퍼월 적용 여부',
        rewardPoint: '지급 포인트',
        target: '배너위치',
        id: 'ID',
        name: '이름',
        title: '제목',
        subTitle: '부제목',
        content: '랜딩 ID / URL',
        startDate: '시작일',
        endDate: '마감일',
        createdAt: '생성일',
        adUrl: '광고 파라미터',
        type: '랜딩페이지',
        imagePath: '이미지'
      }
    },
    donation: {
      name: '기부 포인트 관리',
      fields: {
        id: 'ID',
        name: '이름',
        imagePath: '이미지',
        type: '랜딩이미지',
        target: '배너위치',
        userClick: '누적 클릭수',
        startDate: '시작일',
        endDate: '마감일',
        createdAt: '생성일'
      }
    },
    donationDestination: {
      name: '기부처 관리',
      fields: {
        id: 'ID',
        name: '이름'
      }
    },
    donationGoods: {
      name: '기부물품 관리',
      fields: {
        id: 'ID',
        name: '이름'
      }
    },
    offerwall: {
      name: '클릭 보너스 관리',
      fields: {
        id: 'ID',
        title: '제목',
        subTitle: '부제목',
        titleImagePath: '대표 이미지',
        onOff: '표출 여부',
        isAd: '광고 표기 여부',
        userClick: '누적 클릭수',
        type: '오퍼월 타입',
        rewardType: '리워드 타입',
        rewardImage: '리워드 이미지',
        rewardValue: '리워드 값',
        landingType: '랜딩 페이지',
        landingValue: '랜딩 ID / URL',
        startDate: '시작일',
        endDate: '마감일',
        createdAt: '생성일'
      }
    },
    jointPurchase: {
      name: '공동구매 관리',
      fields: {
        id: 'ID',
        type: '타입',
        form: '이벤트 형태',
        content: '부제목',
        images: '이미지',
        titleImagePath: '대표 이미지',
        bottomImages: '하단 이미지',
        title: '제목',
        pushTitle: '푸시 제목(수정할 것)',
        push: '푸쉬 알림',
        totalReadCount: '조회 수',
        readCount: '조회 수',
        totalLikeCount: '좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '댓글 수',
        commentCount: '댓글 수',
        rolling_order: '노출 순서',
        createdAt: '생성일',
        updatedAt: '수정일',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        periodStart: '오픈 날짜',
        periodEnd: '마감 날짜'
      }
    },
    sellers: {
      name: '판매 업체 관리',
      fields: {
        packingCount: '합배송 가능 갯수',
        deliveryFee: '배달 요금',
        todayFee: '당일 배송 추가 배송비',
        extraFee: '도서 산간 추가 배송비',
        address: '주소',
        id: 'ID',
        tels: '연락처',
        name: '업체 명',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        defaultFee: '기본 배송비 설정',
        minimumPrice: '무료배송 기준 금액',
        packing: '배송요금 유/무',
        packingPolicy: '배송 정책',
        sellerId: '입점사 로그인 ID',
        password: '입점사 로그인 패스워드'
      }
    },
    sellerNotices: {
      name: '업체 공지사항 관리',
      summary: '입점사에게 공지하는 메뉴',
      fields: {
        name: 'ID',
        question: '제목',
        answer: '등록일'
      }
    },
    // -------- 주문 관리
    orders20: {
      name: '주문 관리',
      fields: {
        id: '상품주문 번호',
        orderId: '대주문 번호',
        productOrderId: '상품-주문 번호',
        userId: '사용자',
        buyerName: '주문자',
        buyerPhone: '휴대전화',
        amount: '결제 금액',
        point: '포인트',
        createdAt: '생성일',
        status: '주문 상태',
        receiverName: '수령자명',
        receiverTel: '연락처',
        receiverAddress: '주소',
        method: '결제 수단',
        deliveryFee: '배송비',
        extraFee: '도서 산간 추가 배송비',
        memo: '주문자 메모',
        adminMemo: '관리자 메모',
        isTodayOrder: '당일배송',
        vbank: {
          expire: '가상계좌 만료시간',
          holder: '가상계좌 예금주',
          name: '가상계좌 은행',
          number: '가상계좌 번호'
        },
        vbankRefund: {
          bank: '환불계좌 은행',
          holder: '환불계좌 예금주',
          account: '환불계좌 번호'
        },
        trackingNumber: '송장번호',
        trackingNumberStr: '송장번호',
        zipCode: '우편번호',
        quantity: '수량',
        productNumber: '상품코드'
      }
    },
    balanceAccount: {
      name: '정산 관리',
      summary: '물품 정산용 서브 메뉴',
      fields: {}
    },
    // -------- 장소 관리
    places: {
      name: '장소 관리',
      fields: {
        recommendPick: '추천 Pick',
        accounts: {
          email: '이메일'
        },
        coordinate: {
          x: '경도',
          y: '위도'
        },
        order: '우선 순위',
        dfangName: '디팡 이름',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalLikeCount: '누적 좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '누적 댓글 수',
        commentCount: '댓글 수',
        id: 'ID',
        keywordIds: '장소 키워드',
        facilityIds: '편의시설 및 서비스',
        serviceIds: '업종',
        divide: '분류',
        name: '업체명',
        adminName: '이름',
        email: '이메일',
        address: '주소',
        addressDetail: '상세주소',
        titleImage: '대표 이미지',
        description: '업체 소개',
        images: '이미지',
        breedIds: '견종',
        tel: '전화',
        confirmed: '업체 인증',
        createdAt: '생성일',
        updatedAt: '수정일',
        titleImagePath: '대표 이미지',
        weekdayBusinessHourStart: '주중 오픈 시간',
        weekdayBusinessHourEnd: '주중 마감 시간',
        weekendBusinessHourStart: '주말 오픈 시간',
        weekendBusinessHourEnd: '주말 마감 시간',
        parking: '주차 가능',
        dfangDiscount: '디팡 할인',
        useCoupon: '쿠폰 사용',
        usePoint: '포인트 사용',
        homepage: '홈페이지',
        kakaotalk: '카카오톡',
        facebook: '네이버',
        instagram: '인스타그램',
        blog: '블로그',
        type: '분류'
      }
    },
    facilities: {
      name: '편의시설/서비스 관리',
      fields: {
        id: 'ID',
        name: '이름',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    services: {
      name: '업종 관리',
      fields: {
        id: 'ID',
        name: '이름',
        isActive: '표출 여부',
        imagePath: '이미지',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        order: '우선 순위'
      }
    },
    placeBreeds: {
      name: '장소 견종 관리',
      fields: {
        id: 'ID',
        name: '이름',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    placeKeywords: {
      name: '장소 키워드 관리',
      fields: {
        id: 'ID',
        name: '이름',
        adminName: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        recommended: '추천 여부',
        order: '우선 순위'
      }
    },
    // -------- 컨텐츠 관리ㄹ
    knowledge: {
      name: '펫피공감 관리',
      fields: {
        id: 'ID',
        cateName: '카테고리',
        type: '종류',
        name: '이름',
        title: '제목',
        subTitle: '부제목',
        images: '상세 이미지',
        createdAt: '생성일',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalLikeCount: '누적 좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '누적 댓글 수',
        commentCount: '댓글 수',
        thumbnailPath: '대표 이미지',
        content: '내용'
      }
    },
    categorySympathy: {
      name: '펫피공감 카테고리',
      fields: {
        id: 'ID',
        name: '이름',
        order: '우선 순위',
        createdAt: '생성일'
      }
    },
    reviews: {
      name: '제품리뷰 관리',
      fields: {
        id: 'ID',
        name: '이름',
        title: '제목',
        type: '종류',
        subTitle: '부제목',
        thumbnailPath: '대표 이미지',
        content: '내용',
        createdAt: '생성일',
        updatedAt: '수정일',
        totalReadCount: '누적 조회 수',
        readCount: '조회 수',
        totalLikeCount: '좋아요 수',
        likeCount: '좋아요 수',
        totalCommentCount: '댓글 수',
        commentCount: '댓글 수',
        images: '상세 이미지',
        isAdOn: '광고'
      }
    },
    deletedOrders: {
      name: '삭제된 주문 목록',
      fields: {
        id: 'ID',
        phone: '전화번호',
        name: '이름',
        email: '이메일',
        createdAt: '생성일',
        updatedAt: '수정일',
        userId: '사용자',
        buyerName: '주문자',
        buyerPhone: '휴대전화',
        amount: '결제 금액',
        point: '포인트',
        status: '주문 상태',
        receiverName: '수령자명',
        receiverTel: '연락처',
        receiverAddress: '주소',
        method: '결제 수단',
        deliveryFee: '배송비',
        extraFee: '도서 산간 추가 배송비',
        memo: '주문자 메모',
        vbank: {
          expire: '가상계좌 만료시간',
          holder: '가상계좌 예금주',
          name: '가상계좌 은행',
          number: '가상계좌 번호'
        },
        vbankRefund: {
          bank: '환불계좌 은행',
          holder: '환불계좌 예금주',
          account: '환불계좌 번호'
        },
        trackingNumber: '송장번호',
        trackingNumberStr: '송장번호',
        zipCode: '우편번호',
        quantity: '수량'
      }
    }
  },
  Unauthorized: 'Unauthorized',
  user: {
    list: {
      search: 'Search'
    },
    form: {
      summary: 'Summary',
      security: 'Security'
    },
    edit: {
      title: 'User "%{title}"'
    },
    action: {
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show'
    }
  },
  errors: {
    common: {
      name: '이름',
      value: '정보'
    },
    simple: {
      action: {
        close: 'Close',
        save: 'Save'
      }
    },
    auth: {
      notValidUserName: '유효한 이름이 아님',
      notValidPassword: '유효한 패스워드가 아님'
    }
  },

  NotFound: '찾을 수 없습니다.',
  AlreadyInUse: '이미 사용중입니다.',
  SamePassword: '기존 패스워드와 동일합니다.'
}

export default messages
