import React from 'react'

import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import ImageClickField from '../../../custom/common/ImageClickField'
import TalkPagingButton from './component/TalkPagingButton'
import CommentButton from './component/CommentButton'
import {CardActions, EditButton, ListButton} from 'ra-ui-materialui'

const ShowActions = ({basePath, data}) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <EditButton basePath={basePath} record={data} />
      <CommentButton record={data} type={'comment'} />
    </CardActions>
  )
}

const Expand = ({id, record, resource, ...props}) => {
  return (
    <>
      {record && (
        <ArrayField record={record} {...props} source="child" label={'답글'} addLabel={false}>
          <Datagrid>
            <TextField source="id" label={'ID'} sortable={false} />
            <ReferenceField source="userId" reference="users" linkType={'show'} label={'유저'} basePath="/users">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="content" label={'내용'} sortable={false} />
            <DateField source="createdAt" label={'작성일'} locales="ko" showTime sortable={false} />
          </Datagrid>
        </ArrayField>
      )}
    </>
  )
}

class TalkShow extends React.Component {
  render() {
    const {...props} = this.props

    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />
            <ReferenceField label="사용자 이름" source="userId" reference="users" linkType="show">
              <TextField source="name" />
            </ReferenceField>
            <SelectField
              source="category"
              choices={[
                {id: 'board', name: '게시판'},
                {id: 'gallery', name: '산책인증'},
                {id: 'walk', name: '산책'},
                {id: 'counseling', name: '상담'}
              ]}
            />
            <ImageClickField label="이미지" addLabel={true} source="images" src="image" />
            <TextField source="title" />
            <TextField source="description" />
            <DateField source="createdAt" locales="ko" showTime />
            <TalkPagingButton {...props} />
          </Tab>
          <Tab label={'댓글 정보'}>
            <ArrayField source="comments" addLabel={false}>
              <Datagrid aria-expanded="false" expand={<Expand />}>
                <TextField source="id" sortable={false} />
                <ReferenceField source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="content" label={'내용'} sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
                <CommentButton {...props} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(TalkShow)
