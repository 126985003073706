import {UPDATE} from 'react-admin'

// [JJ] 커뮤니티 게시글 '상단 고정'
const customMethodByPinned = 'PINNED'

export function request(type, params) {
  let url = '/admin/v3/community'
  let options = {}

  switch (type) {
    // [JJ] 커뮤니티 게시글 '상단 고정'
    case customMethodByPinned:
      // endPoint 추가
      url += `/${params.id}/pinned`
      // PUT Method 설정
      options.method = 'PUT'
      options.data = params.data
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    // [JJ] 커뮤니티 게시글 '상단 고정'
    case customMethodByPinned:
      break

    default:
      break
  }
  ret = {data}
  return ret
}
