import BookIcon from '@material-ui/icons/Book'
import TalkShow from './show/TalkShow'
import TalkEdit from './show/edit/TalkEdit'
import TalkList from './TalkList'

export default {
  edit: TalkEdit,
  list: TalkList,
  show: TalkShow,
  icon: BookIcon
}
