import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  ImageField,
  List,
  NumberField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField
} from 'react-admin'
import { withDataProvider } from 'ra-core'  // 이 부분 수정
import { compose } from 'recompose'  // compose import 추가
import TalkListExcelExportButton from './component/TalkListExcelExportButton'
import PinnedToggleButton from './component/PinnedToggleButton'
import {parse, stringify} from 'querystring'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = (props) => (
  <CardActions>
    <TalkListExcelExportButton {...props} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="아이디" source="searchId" alwaysOn/>
    <SearchInput label={props.translate('resources.talks.fields.title')} source="search" alwaysOn/>
    <SearchInput label="유저명" source="user" alwaysOn/>
    <SelectInput
      source="category"
      choices={[
        {id: 'board', name: '게시판'},
        {id: 'walk', name: '산책'},
        {id: 'gallery', name: '산책인증'},
        {id: 'counseling', name: '상담'}
      ]}
      alwaysOn
    />
    <ReferenceInput
      source="community_category"
      label="커뮤니티 카테고리"
      reference="communityCategory"
      {...props}
      perPage={200}
      alwaysOn
    >
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <DateInput label="시작 날짜" source="startDate" alwaysOn/>
    <DateInput label="종료 날짜" source="endDate" alwaysOn/>
  </Filter>
)

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

export default withStyles(styles)(({classes, history, ...props}) => {
  const queryStr = () => {
    const {
      location: {search}
    } = props
    const query = parse(decodeURIComponent(search))
    return query.hasOwnProperty('?filter') ? JSON.parse(query['?filter']) : null
  }
  return (
    <List {...props} actions={<Actions/>} perPage={25} filters={<ListFilter/>} bulkActions={true}>
      <Datagrid rowClick={(id, basePath) => `${basePath}/${id}/show?${stringify(queryStr())}`}>
        <TextField source="id" label="게시글 ID" sortable={true}/>
        <TextField source="userId" label="유저 ID" sortable={true}/>
        <TextField source="author.name" label="닉네임" sortable={false}/>
        <TextField source="cateName" label="카테고리" sortable={false}/>
        <ThumbnailImageField source="thumbnailImage" sortable={false}/>
        <SelectField
          source="category"
          choices={[
            {id: 'board', name: '게시판'},
            {id: 'gallery', name: '산책인증'},
            {id: 'walk', name: '산책'},
            {id: 'counseling', name: '상담'}
          ]}
          sortable={false}
        />
        <TextField source="title" sortable={false}/>
        <PinnedToggleButton
          source="isPinned"
          label="상단 고정 설정"
        />
        <NumberField source="totalReadCount" sortable={true}/>
        <NumberField source="readCount" sortable={true}/>
        <NumberField source="totalLikeCount" sortable={true}/>
        <NumberField source="likeCount" sortable={true}/>
        <NumberField source="totalCommentCount" sortable={true}/>
        <NumberField source="commentCount" sortable={true}/>
        <DateField source="createdAt" locales="ko" showTime sortable={true}/>
      </Datagrid>
    </List>
  )
})
