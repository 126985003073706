import React, {useCallback, useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import {ListButton} from 'ra-ui-materialui'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {dataProvider} from '../../../../App'
import {GET_ONE} from 'ra-core'
import {parse} from 'query-string'

const TalkPagingButton = ({record, history, ...rest}) => {
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(0)
  const createButton = useCallback(async () => {
    if (record && record.hasOwnProperty('ids')) {
      if (rest.location.search.length > 0) {
        const res = await dataProvider(GET_ONE, 'talks', {id: rest.id, query: parse(rest.location.search)})
        res.data.ids.forEach((id, i) => {
          if (id === res.data.id) {
            setRight(res.data.ids[i - 1])
            setLeft(res.data.ids[i + 1])
          }
        })
      } else {
        record.ids.forEach((id, i) => {
          if (id === record.id) {
            setRight(record.ids[i - 1])
            setLeft(record.ids[i + 1])
          }
        })
      }
    }
  }, [record, rest.id, rest.location.search])
  useEffect(() => {
    if (record) createButton()
  }, [createButton, record, rest.location])

  const LeftClick = () => {
    history.push(`/talks/${left}/show/${rest.location.search}`)
  }
  const RightClick = () => {
    history.push(`/talks/${right}/show${rest.location.search}`)
  }

  return (
    <>
      {left > 0 && (
        <Button onClick={LeftClick}>
          <ChevronLeftIcon />
        </Button>
      )}
      <ListButton label="" basePath={rest.basePath} />
      {right > 0 && (
        <Button onClick={RightClick}>
          <ChevronRightIcon />
        </Button>
      )}
    </>
  )
}

export default TalkPagingButton
