import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../../App'
import {excelDownload} from '../../../custom/common/excelDownload'

const TalkListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'ID',
        'userId',
        '닉네임',
        '카테고리',
        '게시유형',
        '제목',
        '내용',
        '누적 조회수',
        '조회 수',
        '누적 좋아요 수',
        '좋아요 수',
        '누적 댓글 수',
        '댓글 수',
        '생성일'
      ]
    ]
    const {
      data: {data}
    } = await dataProvider('EXCEL', 'talks')

    // order -> talk 로 변경
    data.forEach((talk, index) => {
      try {
        dataArr.push([
          talk.id,
          talk.userId,
          talk.author.name,
          talk.cateName,
          talk.category,
          talk.title,
          talk.description ? talk.description : '',
          talk.totalReadCount,
          talk.readCount,
          talk.totalLikeCount,
          talk.likeCount,
          talk.totalCommentCount,
          talk.commentCount,
          new Date(talk.createdAt).toLocaleString()
        ])
      } catch (e) {
        console.log(e)
      }
    })
    excelDownload(dataArr, '게시글 리스트 목록')
  }
  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default TalkListExcelExportButton
